import { Typography } from "@mui/material";
import { Link } from "@remix-run/react";

export const PLATFORM_FEE_RATE = 0.1;

export const PLATFORM_TERMS_URL = "https://wishtender.com/files/WishTender_Terms_of_Service.pdf";
export const PLATFORM_PRIVACY_URL = "https://wishtender.com/files/privacy.pdf";

export const PAYMENT_TRANSITION_DETAIL_URL =
  "https://intercom.help/wishtender/en/articles/8909453-wishtender-transitioning-away-from-stripe";

export const SUPPORTED_COUNTRIES_DETAIL_URL =
  "https://intercom.help/wishtender/en/articles/8928124-payouts-fees-and-countries-beta";
export const PAYOUT_FEES_DETAIL_URL =
  "https://intercom.help/wishtender/en/articles/8928124-payouts-fees-and-countries-beta";
export const PAYOUT_SETUP_FAQ_URL =
  "https://intercom.help/wishtender/en/articles/8955074-general-faqs-around-the-new-payment-system";
export const TIPS_AND_SOLUTIONS_FOR_COMMON_ERRORS_URL =
  "https://intercom.help/wishtender/en/articles/8955074-setting-up-your-payout-account";
export const GUIDES_FOR_GETTING_STARTED_URL =
  "https://intercom.help/wishtender/en/collections/8411798-payments-and-payouts";
export const BROWSE_HELP_CENTER_URL = "https://intercom.help/wishtender/en/";
export const PAYMENT_SYSTEM_URL =
  "https://intercom.help/wishtender/en/collections/8411798-payments-and-payouts";
export const FIRST_GIFT_EXPECTATION_URL =
  "https://intercom.help/wishtender/en/articles/9352066-what-to-expect-after-receiving-your-first-gift";

export const PLATFORM_GOOGLE_ANALYTICS_ID = "G-M86T2R2BTE";
export const PLATFORM_GOOGLE_TAG_MANAGER_ID = "GTM-KFL8FCT";
export const PLATFORM_INTERCOM_APP_ID = "f35wmkis";

export const PLATFORM_AGE_LIMITATION = 18;
export const PLATFORM_USER_BLOCKED_REASONS = [
  `Your verification failed because your documents showed that you are under ${PLATFORM_AGE_LIMITATION} years of age. Only users who are ${PLATFORM_AGE_LIMITATION}+ can use WishTender and you have violated our Payout Agreement.`,
  "Due to restrictions imposed by international regulations and payment processing requirements, WishTender cannot support creator accounts in Russia at this time.",
];

export const TERMS_CONTENT = (
  <ul style={{ marginTop: "0" }}>
    <li>
      I am making a <b>non-refundable</b> cash gift donation.
    </li>
    <li>
      I{" "}
      <b>
        expect <u>no</u> product or service in return
      </b>{" "}
      from the gift recipient.
    </li>
    <li>This payment is a donation intended for the gift recipient.</li>
    <li>
      I have taken the necessary steps to confirm the wishlist owner is authentic and I understand
      that{" "}
      <b>
        WishTender will not be held responsible for any issues arising from a catfishing situation.
      </b>
    </li>
    <li>
      I understand that by violating these terms I may be subject to legal action or can fall a
      victim of scams.
    </li>
    <li>
      {" "}
      I understand that by checking the box above and then clicking "CHECKOUT", I will have created
      a <b>legally binding e-signature</b> to this agreement.
    </li>
  </ul>
);

export const TERMS_POLICY_AGREEMENT_LABEL = (
  <Typography component="span" variant="b5">
    I agree to the{" "}
    <Link to={PLATFORM_TERMS_URL} target="_blank" rel="noreferrer noopener">
      Terms of Service
    </Link>{" "}
    and{" "}
    <Link to={PLATFORM_PRIVACY_URL} target="_blank" rel="noreferrer noopener">
      Privacy Policy
    </Link>
  </Typography>
);
